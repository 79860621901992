<template>
  <div class="content_class">
    <div class="van-ellipsis">隐私政策</div>
    <div class="black_class"></div>

    <div class="van-ellipsis">本版发布日期：2022年8月22日</div>
    <div class="black_class"></div>

    <div class="van-ellipsis">生效日期：2022年8月27日</div>
    <div class="black_class"></div>

    <div class="privacy_policy_class">
      <pre>
        我们深知个人信息对您的重要性，也感谢您对我们的信任。我们将通过本政策向您说明我们会如何收集、存储、保护、使用及对外提供您的信 息，并说明您享有的权利，其中要点如下：

      1. 为了让您便于了解您在使用我们的产品和/或服务时，我们需要收集的信息类型与用途，我们将结合具体产品和/或服务向您说明。
      2. 为了向您提供服务所需，我们会按照合法、正当、必要的原则收集您的信息。
      3. 如果为了向您提供服务而需要将您的信息与第三方共享，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意。
      4. 如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性。
      5. 如果我们将信息用于本政策未载明的其他用途、将基于特定目的收集而来的信息用于其他目的时，或我们开展业务需进行的个人信息处理行为超出您原本向第三方提供个人信息时的授权范围，会事先征求您的明确同意。
      6. 您可以通过本政策介绍的方式访问和管理您的信息、设置隐私功能、注销账户或进行投诉举报。
      您可以根据以下索引阅读相应章节，进一步了解本政策的具体内容：

      一. 引言
      二. 定义
      三. 我们如何收集和使用您的个人信息
      四. 我们如何使用cookies和类似技术
      五. 我们如何与第三方共享、转让、披露您的个人信息
      六. 我们如何保存和保护您的个人信息
      七. 您如何访问和管理自己的信息
      八. 停止运营
      九. 我们如何保护未成年人的个人信息
      十. 隐私政策的适用例外
      十一. 本政策的变更
      十二. 联系、投诉及举报
      十三. 产品和/或服务运营者信息
      十四. 适用法律与争议解决

      一. 引言
      尔文（成都）网络科技有限公司 重视用户的隐私。您在使用我们的服务时，我们会收集和使用您的相关信息。我们希望通过本政策向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本政策与您所使用的我们的产品和/或服务息息相关，希望您仔细阅读，在需要时，按照本政策的指引，作出您认为适当的选择。本政策中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接（如有必要），以便您的理解。
      特别说明的是，本政策适用于尔文（成都）网络科技有限公司旗下现存有效以及将来可能发布运行的平台、产品、服务，如果我们关联公司（范围见定义部分）的产品和/或服务使用了我们提供的产品和/或服务但未设独立的隐私政策的，则本政策同样适用该部分产品和/或服务。
      为了使您充分理解本声明，本声明中与您的权益存在或可能存在重大关系的条款，我们已采用加粗字体提醒您注意。
      如果您在初始安装、首次使用或首次登录/注册账号时，勾选或点击“同意”本《隐私政策》的，代表您同意本《隐私政策》。但这并不代表我们会立即收集本《隐私政策》包含的所有个人信息，只有在您通过浏览、主动填写、点击等自主性行为开启、使用某项功能时，我们才会按照本《隐私政策》收集、使用该功能对应的个人信息。
      如果您未勾选或点击“同意”本《隐私政策》而继续使用，视为您接受和认可我们按照本《隐私政策》对您的相关信息进行处理。
      如果您不同意本《隐私政策》，请不要使用或停止使用我们的产品和/或服务。
      我们向您提供的功能：

      二. 定义
      1. 尔文（成都）网络科技有限公司产品和/或服务
      头号星球app。
      2. 关联公司
      指与尔文（成都）网络科技有限公司之间存在直接或间接控制关系或重大影响关系的公司，包括但不限于以下情形：相互间直接或者间接持有其中一方的股份总和达到25%或以上的；直接或间接同为第三者所拥有或控制股份达到25%或以上的；尔文（成都）网络科技有限公司与另一公司之间借贷资金占尔文（成都）网络科技有限公司实收资本50%或以上；该关联公司的董事或经理等高级管理人员一半以上或有一名常务董事由尔文（成都）网络科技有限公司委派。

      三. 我们如何收集和使用您的个人信息
      我们收集信息是为了向所有用户提供更好的服务。我们收集信息的主要来源有：您向尔文（成都）网络科技有限公司提供的信息、尔文（成都）网络科技有限公司在您使用尔文（成都）网络科技有限公司旗下产品和/或服务过程中获取的信息、您通过我们的产品和/或服务向其他方提供的共享信息。我们会合法合理且最小化收集您的个人信息。在收集前，会明确告知您我们收集的个人信息类型、使用的规则并获得您的授权同意。
      尔文（成都）网络科技有限公司将出于以下目的，收集和使用您的个人信息：
      一. 注册/登录
      如果您使用购物、分享、提现、邀请、查看购物车、查看订单、激活微信、我的红包等功能，则需要注册/登录（登录即代表您注册成为我们的用户）客户端，我们将根据您注册/登录的方式，收集以下个人信息：
      1. 手机号码，是您选择使用手机号码注册/登录时，主动向我们提供的个人信息。同时，我们将通过发送短信验证码的方式来验证您的身份是否有效。您还可以通过手机号码找回、修改账号密码。
      2. 第三方应用账号（淘宝）和昵称、头像等公开信息（请以授权登录界面显示的信息为准），是您选择使用第三方账号注册/登录，并授权尔文（成都）网络科技有限公司读取您在该第三方账号注册的上述信息。
      如果您不提供这些信息，则您无法注册/登录我们产品，且无法使用需注册/登录的功能，但不会影响您正常使用无需注册/登录的功能。
      二. 您可以通过文字来搜索内容
      当您键入文字搜索时，我们会在客户端本地保存您的搜索关键字信息、搜索历史记录，这是为了向您避免您下次重复输入同样的搜索内容，以及为了我们能够持续改进和优化上述功能。
      三. 设备信息
      我们会根据您在软件安装或使用中的具体操作，接收并记录您所使用的设备相关信息（包括硬件型号、MAC地址、版本信息、操作系统、设备设置、唯一设备标识符（IMEI、IMSI、IDFV、IDFA、安卓广告主标识符）、电话状态、设备环境、SIM卡信息、终端制造厂商、语言所在地、硬盘、CPU和电池使用情况、电信运营商、使用语言）、位置信息（包括IP地址、您授权的GPS位置、WLAN接入点、蓝牙、基站以及其他传感器信息）。如您使用安卓设备，需要您在开启设备信息权限。
      四. 购物车信息查询
      您可以在购物车页面查看您的购物车信息以及相关商品展示，这需要您的淘宝账号授权，如您未进行淘宝授权，将影响您通过我们的客户端购物车购买淘宝商品。
      五. 淘宝订单信息查询
      您可以在您的订单页面查看您的订单详情（即：您的淘宝历史订单），这需要您的淘宝账号授权，如您未进行淘宝授权，将影响您使用我们的客户端购买淘宝商品。
      六. 返利订单查询
      您可以在我的返利页面查看您的返利订单，这需要您登录我们的客户端，如您未登录，我们将无法根据账号信息查询到对应的返利订单记录。
      七. 支付宝身份验证
      您在我们的客户端获得的订单返利以及相关奖励提现至支付宝时，需要提供您的支付宝账号、姓名及身份证，以完成对您支付宝账户的校验，若您未提供相关信息验证，将影响您的账户提现功能。
      八. 微信授权
      当您在微信内分享红包以及赚赚商品，或者帮助您的好友进行助力的时候，需要您在羊毛省钱APP内进行微信授权，若您未进行授权，将会影响您的分享及助力。
      九. 消息推送
      若您需要接收我们的消息推送（短信、push、站内信），及时了解您通过我们的产品和/或服务购买的产品或服务之返利到账信息、优惠活动信息，我们会向您提供推送信息服务，此项功能需要使用您的联系电话、开启接收权限。如果您不同意接收前述信息，可以通过短信提示回复退订或我们提供的其他方式进行退订。您需要开启相应通知授权，我们可以在第一时间将相关信息推送给您，如未授权，不影响您使用我们提供的其他服务。
      十. 访问我们的产品和/或服务
      设置相应的网络，可以帮助您更好的访问我们的产品和/或服务。
      十一. 接收通知
      通知权限包括横幅、声音和标记，如您开启相应通知授权，我们可以在第一时间将相关信息推送给您，如未授权，不影响您使用我们提供的其他服务。
      十二. 猜你想搜
      我们会获取您客户端所在设备的剪切板信息，并判断该剪切板信息后向您展示更符合您的兴趣和需求的商品信息。
      十三. 提高图片访问速度
      我们会将您使用我们的产品和/或服务过程中下载的图片缓存在外部存储器上（此权限仅基于Android系统开发），为了提高您再次访问前述图片时的速度。
      十四. 为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入、违法违规内容排查等安全风险，更准确地识别违反法律法规或我们制定的相关协议规则的情况，我们可能获取您的账号信息、交易信息、设备信息、日志信息以及我们关联公司、第三方合作伙伴取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      1. 设备信息
      尔文（成都）网络科技有限公司可能收集您的设备及软件相关信息，例如您的设备品牌及型号、IP地址、设备软件版本信息、设备识别码。
      2. 日志信息
      当您使用我们的产品和/或服务时，我们会收集您对我们服务的详细使用情况，作为有关网络日志保存，包括：您的搜索查询内容（如搜索词语）、IP地址、浏览器的类型、访问的社交媒体页面url地址、访问日期和时间、系统的错误日志及访问记录。
      十五. 征得授权同意的例外
      根据相关法律法规规定，出现以下情形时，我们收集和使用您的个人信息无需征得您的授权同意：
      1. 与国家安全、国防安全有关的；
      2. 与公共安全、公共卫生、重大公共利益有关的；
      3. 与犯罪侦查、起诉、审判和判决执行等有关的；
      4. 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      5. 所收集的个人信息是您自行向社会公众公开的；
      6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
      7. 根据您的要求签订合同所必需的；
      8. 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
      9. 为合法的新闻报道所必需的；
      10. 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      11. 法律法规规定的其他情形。
      请您理解并同意，如您的信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；只有当您的信息可以单独或结合其他信息识别到您的个人身份时，则在结合使用期间，将作为您的个人信息按照本政策处理与保护。

      四. 我们如何使用cookies和类似技术
      一. 我们或我们的第三方合作伙伴，可能通过cookies和web beacon收集和使用您的信息，并将该等信息储存为日志信息。
      二.我们使用自己的cookies和类似技术，目的是为您提供更好的用户体验和服务，并用于以下用途：
      1. 记住您的身份。例如：cookies有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；
      2. 分析您使用我们产品和/或服务的情况。例如，我们可利用cookies和web beacon来了解您使用我们的产品和/或服务进行什么活动，或哪些网页或服务最受您的欢迎；
      3. 记录您的使用习惯、判断数据安全。为使您获得更轻松的访问体验，您访问平台相关网站或使用平台提供的服务时，我们可能会通过采用各种技术收集和存储相关信息，包括使用小型数据文件识别您的身份，收集您浏览网页活动信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等，这么做是为了解您的使用习惯，帮您省去重复输入注册信息的步骤，或者帮助判断您的账号安全。
      三.您可以通过浏览器设置拒绝或管理cookies或web beacon。但请注意，如果停用cookies或web beacon，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。
      四.请您理解，我们的某些服务只能通过使用Cookies才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookies的接受程度或者拒绝我们的Cookies，但拒绝我们的Cookies在某些情况下可能会影响您安全访问平台相关网站和使用平台提供的服务。

      五. 我们如何与第三方共享、转让、披露您的个人信息
      我们会向您告知共享的目的、数据接收方的合法性、并事先征得您的同意。准确记录和保存您个人信息的共享的情况，包括共享的日期、规模、目的，以及数据接收方的基本情况。
      一. 共享
      1. 我们不会与其他任何公司、组织和个人共享您的个人信息，但以下情况除外：
      1.1 事先获得您明确的同意或授权；
      1.2 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
      1.3 在法律法规允许的范围内，为维护尔文（成都）网络科技有限公司、尔文（成都）网络科技有限公司的关联方或合作伙伴、您或其他尔文（成都）网络科技有限公司用户或社会公众利益、财产或安全免遭损害而有必要提供；
      1.4 只有共享您的信息，才能实现我们和/或关联公司、与我们合作的第三方提供的产品和/或服务的核心功能或提供您需要的服务；
      1.5 应您需求为您处理您与他人的纠纷或争议；
      1.6 符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      1.7 基于学术研究而使用；
      1.8 国家及行业主管部门法律法规规定的需要向特定第三方（如第三方审计机构、企业上市监管部门）披露的信息；
      1.9 尔文（成都）网络科技有限公司及关联公司内部的必要共享。我们只会共享必要的个人信息，且受本《隐私政策》中所声明目的的约束。如要改变个人信息的处理目的，将再次征求您的授权同意。
      1.10 基于符合法律法规的社会公共利益而使用。
      2. 对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
      3. 为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护尔文（成都）网络科技有限公司、您或其他尔文（成都）网络科技有限公司客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
      二. 转让
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      1. 事先获得您明确的同意或授权；
      2. 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
      3. 符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      4. 随着尔文（成都）网络科技有限公司业务及公司经营状况的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
      三. 公开披露
      我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
      1. 根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
      2. 根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
      四. 征得同意的例外
      请您充分知晓，以下情形中，共享、转让、公开披露您的个人信息的，无需事先征得您的授权同意：
      1. 与国家安全、国防安全直接相关的；
      2. 与公共安全、公共卫生、重大公共利益直接相关的；
      3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；
      4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      5. 您自行向社会公众公开的个人信息；
      6. 从合法公开披露的信息中收集的您的个人信息，如合法的新闻报道、政府信息公开等渠道。

      使用的SDK列表

      为保障《头号星球》的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们会对合作方获取个人信息的SDK进行严格的审核，以保护您的个人信息安全。我们对接入的相关第三方SDK在以下目录中列明。您可以通过相关链接查看第三方的数据使用和保护规则。请注意，第三方SDK可能由于版本升级、策略调整等原因导致其个人信息处理类型发生变化，请以其公示的官方说明为准。

      友盟SDK（com.umeng）
      接入目的：数据统计、数据分析，统计用户APP行为画像，以便优化用户体验
      收集个人信息类型：设备信息（IMEI/Mac/Android ID/IDFA/OPENUDID/GUID/SIM 卡 IMSI 信息）、获取设备序列号
      隐私权政策链接：https://www.umeng.com/page/policy

      TOP-SDK(淘宝/阿里妈妈)
      使用目的：用于获取淘宝商品信息、商品订单及状态等信息收集信息：包括设备标识符（IMEI、IDFA、Android ID、MAC、OAID、IMSI及其他设备相关信息）、应用信息（应用崩溃信息、通知开关状态、应用安装列表及其他应用相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络环境信息（IP地址，WiFi信息，基站信息及其他网络相关信息）隐私政策链接：https://terms.alicdn.com/legal-agreement/terms/suit_bu1_ali_mama_division/suit_bu1_ali_mama_division202107201832_24375.html

      微信及微信登录SDK
      使用目的：用于分享到微信一键登录
      数据类型：网络状态信息

      穿山甲广告SDK
      使用目的：用于APP内广告分发
      收集信息：设备基础信息（MAC地址、IP地址、设备地理位置、设备标识符（如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID）、应用版本信息以及网络信息。
      隐私政策链接：https://www.csjplatform.com/privacy/partner

      腾讯优量汇SDK
      用于APP内广告分发
      使用目的：程序化广告推送、广告监测与归因收集个人信息
      数据类型：粗略位置信息、设备信息（如设备制造商、设备型号、操作系统版本等）、设备标识符（如IMEI、IDFA等）、应用信息（宿主应用的包名、版本号）、广告数据（如曝光、点击数据等）。
      隐私政策链接：https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html

      快手SDK
      使用目的：用于APP内广告分发
      可能会收集您的ip地址、设备信息（Android id、IMEI、IMSI、应用安装列表）、屏幕参数、MAC设备地址，地理位置信息等。详细内容请访问《快手隐私政策》https://www.kuaishou.com/about/policy?tab=privacy

      友盟SDK
      接入目的：数据统计、数据分析，统计用户APP行为画像，以便优化用户体验
      收集个人信息类型：设备信息（IMEI/Mac/Android ID/IDFA/OPENUDID/GUID/SIM 卡 IMSI 信息）
      隐私权政策链接：https://www.umeng.com/page/policy

      六. 我们如何保存和保护您的个人信息
      一. 您个人信息的保存
      1. 我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。
      2. 我们综合考虑以下要素来确定保存您的个人信息期限：
      2.1 法律规定的最低存储期限，如《网络安全法》、《电子商务法》等规定的最低保存时间。
      2.2 为提供我们的产品/服务之目的所必需的期限，如在您使用手机号码注册/登录后使用相应的功能时，我们会在您提供且使用该功能期间，持续保存您的手机号码，以向您正常提供该功能。
      2.3 履行其他法定义务而保存的期限，如根据《信息网络传播保护条例》第十三条规定，我们需向著作权行政管理部门提供涉嫌侵权服务对象的姓名（名称）、联系方式等信息。
      2.4 监测、记录网络运行状态、网络安全事件的相关网络日志保存时间不少于六个月。
      3. 超出上述信息保留期限的个人用户信息，我们会依据相关法律法规以及制定的规定，使用格式化等方式对用户个人信息删除或进行匿名化处理。
      4. 我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。
      5. 我们通过数据库表空间加密等加密技术对您的个人信息进行加密存储。
      6. 在要共享您个人信息的场景下，我们会事先开展个人信息安全影响评估，并依据评估结果采取有效的保护个人信息主体的措施。我们会尽量对你的个人信息进行去标识化处理后，再共享。
      二. 您个人信息的保护
      1. 我们设立了个人信息保护责任部门，针对个人信息收集、使用、共享、委托处理等开展个人信息安全影响评估。同时，我们建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并每年组织相关工作人员参加安全培训。另外，我们的相应网络/系统通过了国家网络安全等级保护的测评。我们每年还会聘请外部独立第三方对我们的信息安全管理体系进行评估。
      2. 我们已制定个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。
      3. 我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。
      4. 我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。
      5. 我们采取加密技术对用户个人信息进行加密保存，并通过访问限制技术进行隔离。在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、内容隐藏在内多种数据脱敏技术增强个人信息在使用中安全性。
      6. 我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
      7. 我们采用数据访问日志分析技术进行个人信息安全审计。
      8. 我们为保护个人信息采取的其他安全措施
      8.1 我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
      8.2 我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
      8.3 我们建立个人信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
      8.4 加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      9. 我们仅允许有必要知晓这些信息的相关员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。
      10. 您应理解，互联网并非绝对安全的环境，我们强烈建议您不要使用非尔文（成都）网络科技有限公司@展示的方式发送个…
      </pre>

    </div>



  </div>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.privacy_policy_class{
  padding: 0.32rem;
  background: #fff;
  font-size: 1rem;

}
.privacy_policy_class pre{
  white-space: pre-wrap;
  color: #080808;
  line-height: 1.533333rem;
  word-wrap: break-word;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello{
  float: left;
}
.content_class{
  text-align: justify;
}
.black_class{
  height: 1rem;
}
</style>
